import React, { useEffect, useState} from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { useSelector } from "react-redux";
import {language} from 'config';

function DriverAutocomplete(props) {
  const {selected, onChange} = props;
  const [drivers, setDrivers] = useState([]);
  const usersdata = useSelector(state => state.usersdata);
  const auth = useSelector(state => state.auth);
  
  useEffect(()=>{
    if(usersdata.users){
        setDrivers(usersdata.users.filter(user => user.usertype ==='driver' && ((user.fleetadmin === auth.info.uid && auth.info.profile.usertype === 'fleetadmin')|| auth.info.profile.usertype === 'admin')));
    }else{
      setDrivers([]);
    }
  },[usersdata.users,auth.info.profile.usertype,auth.info.uid]);

  return (
    <Autocomplete 
        options={drivers}
        getOptionLabel={(option) => `${option.firstName} ${option.lastName} ${option?.mobile || option?.email}`}
        style={{ width: "100%" }}
        renderInput={(params) => <TextField disabled={!drivers || (Array.isArray(drivers) && drivers.length === 0)} {...params} label={language.search_driver} variant="outlined" />}
        value={selected}
        onChange={(e, newValue) => {
            onChange(newValue)
        }}
    />
  )
}

export default DriverAutocomplete