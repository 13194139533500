import React, {useEffect, useRef, useState} from 'react'
import GoogleMapsAutoComplete from "../components/GoogleMapsAutoComplete";
import { language } from "config";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {
      "& > *": {
        margin: theme.spacing(1),
      },
    },
    "@global": {
      body: {
        backgroundColor: theme.palette.common.white,
      },
    },
    items: {
      margin: 0,
      width: "100%",
    },
  }));

function EshopAutocompleteAddress(props) {
    const {onChange = () => {}, helperText = ""} = props;
    const pickupPlaceInputRef = useRef();
    
    const [pickupAddress, setPickupAddress] = useState(null);
    const classes = useStyles();
    
    useEffect(() => {
        onChange(pickupAddress)
    }, [pickupAddress]);

  return (
    <GoogleMapsAutoComplete
        ref={pickupPlaceInputRef}
        placeholder={language.pickup_location}
        value={pickupAddress}
        className={classes.items}
        onChange={(value) => {
            setPickupAddress(value);
        }}
        helperText={helperText}
    />
  )
}

export default EshopAutocompleteAddress