import React, { useRef } from "react";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";
import "assets/scss/material-kit-react.scss?v=1.9.0";
import LandingPage from "./views/LandingPage.js";
import LoginPage from "./views/LoginPage.js";
import PrivacyPolicy from "./views/PrivacyPolicy.js";
import AuthLoading from "./views/AuthLoading";
import { Provider } from "react-redux";
import ProtectedRoute from "./views/ProtectedRoute";
import MyProfile from "./views/MyProfile";
import BookingHistory from "./views/BookingHistory";
import DeliveryHistory from "./views/DeliveryHistory";
import PendingOrders from "./views/PendingOrders";
import DeliveryStats from "./views/DeliveryStats";
import Dashboard from "./views/Dashboard";
import CarTypes from "./views/CarTypes";
import PhoneDelivery from "./views/PhoneDelivery";
import NewDelivery from "./views/NewDelivery";
import Promos from "./views/Promos";
import Dispatchers from "./views/Dispatchers";
import Drivers from "./views/Drivers";
import Notifications from "./views/Notifications";
import Settings from "./views/Settings";
import CancellationReasons from "./views/CancellationReasons";
import { Google_Map_API_Key } from "config";
import { SnackbarProvider } from "notistack";
import { store, FirebaseProvider } from "common";

import { features } from "config";

function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement("script");
  script.setAttribute("async", "");
  script.setAttribute("id", id);
  script.src = src;
  position.appendChild(script);
}

var hist = createBrowserHistory();

function App() {
  const loaded = useRef(false);
  if (typeof window !== "undefined" && !loaded.current && !window.google) {
    if (!document.querySelector("#google-maps")) {
      loadScript(
        "https://maps.googleapis.com/maps/api/js?key=" +
          Google_Map_API_Key["web"] +
          "&libraries=geometry,drawing,places",
        document.querySelector("head"),
        "google-maps"
      );
    }
    loaded.current = true;
  }

  return (
    <SnackbarProvider>
      <Provider store={store}>
        <FirebaseProvider>
          <AuthLoading>
            <Router history={hist}>
              <Switch>
                <ProtectedRoute
                  exact
                  component={BookingHistory}
                  path="/bookings"
                  permit={"dispatcher,admin,driver,fleetadmin"}
                />
                <ProtectedRoute
                  exact
                  component={DeliveryHistory}
                  path="/userbookings"
                  permit={"admin,dispatcher,driver"}
                />
                <ProtectedRoute
                  exact
                  component={PendingOrders}
                  path="/pendingorders"
                  permit={"admin"}
                />
                <ProtectedRoute
                  exact
                  component={DeliveryStats}
                  path="/deliverystats"
                  permit={"admin"}
                />
                <ProtectedRoute
                  exact
                  component={MyProfile}
                  path="/profile"
                  permit={"dispatcher,admin,driver,fleetadmin"}
                />
                <ProtectedRoute
                  exact
                  component={Dashboard}
                  path="/dashboard"
                  permit={"admin,fleetadmin"}
                />
                <ProtectedRoute
                  exact
                  component={CarTypes}
                  path="/cartypes"
                  permit={"admin"}
                />
                <ProtectedRoute
                  exact
                  component={CancellationReasons}
                  path="/cancelreasons"
                  permit={"admin"}
                />
                <ProtectedRoute
                  exact
                  component={PhoneDelivery}
                  path="/phonedelivery"
                  permit={"admin"}
                />
                <ProtectedRoute
                  exact
                  component={Promos}
                  path="/promos"
                  permit={"admin"}
                />
                <ProtectedRoute
                  exact
                  component={Dispatchers}
                  path="/dispatchers"
                  permit={"admin"}
                />
                <ProtectedRoute
                  exact
                  component={Drivers}
                  path="/drivers"
                  permit={"admin,fleetadmin"}
                />
                <ProtectedRoute
                  exact
                  component={Notifications}
                  path="/notifications"
                  permit={"admin"}
                />
                <ProtectedRoute
                  exact
                  component={NewDelivery}
                  path="/newdelivery"
                  permit={"dispatcher, admin, driver, fleetadmin"}
                />
                <ProtectedRoute
                  exact
                  component={Settings}
                  path="/settings"
                  permit={"admin"}
                />
                <Route path="/privacy-policy" component={PrivacyPolicy} />
                {features.WithWebsitePages ? (
                  <Route path="/login" component={LoginPage} />
                ) : null}
                {features.WithWebsitePages ? (
                  <Route path="/" component={LandingPage} />
                ) : (
                  <Route path="/" component={LoginPage} />
                )}
              </Switch>
            </Router>
          </AuthLoading>
        </FirebaseProvider>
      </Provider>
    </SnackbarProvider>
  );
}

export default App;
