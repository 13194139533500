export const MainConfig = {
  AppDetails: {
      app_name: "cwee delivery", 
      app_description: "CweeDelivery smart drivers services",
      app_identifier: "com.cwee.delivery",
      ios_app_version: "2.3.0",
      android_app_version: 5
  },
  FirebaseConfig: {
      apiKey: "AIzaSyDBYro5aFjGclFftBFA67at0byQq_fRe5I",
      authDomain: "cweed-delivery.firebaseapp.com",
      databaseURL: "https://cweed-delivery-default-rtdb.firebaseio.com",
      projectId: "cweed-delivery",
      storageBucket: "cweed-delivery.appspot.com",
      messagingSenderId: "832622794680",
      appId: "1:832622794680:web:c2ce2f730b672dcac1614d",
      measurementId: "G-KKS8H7Q1WK"
  },

  Google_Map_API_Key: {
      ios: "AIzaSyAOLQMemKzMzZQsiZT2TTgyxPVs9-jhP4M",
      android: "AIzaSyCiZSZJ4VFIVDYdLjp3KNt4pVb1nWukWJc",
      web: "AIzaSyDBYro5aFjGclFftBFA67at0byQq_fRe5I",
      server: "AIzaSyDX5UllJZfnIRlbRHn3plko1BgL_Qy4Ii0"
  },
  Expo: {
      projectId: "50929183-6e8c-4f6e-af97-a24cfdb83a21"
    }
}
