import React, {
  useState,
  useEffect,
  useMemo,
  forwardRef,
  useImperativeHandle,
} from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import parse from "autosuggest-highlight/parse";
import throttle from "lodash/throttle";
import { geocodeByPlaceId } from "react-places-autocomplete";
import { language } from "config";

const autocompleteService = { current: null };

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
}));

function GoogleMapsAutoComplete(props, ref) {
  const classes = useStyles();
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState([]);

  useImperativeHandle(ref, () => ({
    setInputValue(value) {
      setInputValue(value);
    },
    searchForFirstOption(value) {
      searchForFirstOption(value);
    },
  }));

  const fetch = useMemo(
    () =>
      throttle((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 200),
    []
  );

  const searchForFirstOption = (value) => {
    if (!value) return;
    if (!autocompleteService.current && window.google) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }
    fetch({ input: value }, (results) => {
      if (results) {
        setOptions([...results]);
        let newValue = results[0];
        if (newValue && newValue.place_id) {
          geocodeByPlaceId(newValue.place_id)
            .then((_results) => {
              if (_results.length > 0) {
                newValue.coords = {
                  lat: _results[0].geometry.location.lat(),
                  lng: _results[0].geometry.location.lng(),
                };
                newValue.placeDetails = _results[0];
              }
              props.onChange(newValue);
            })
            .catch((err) => {});
        } else {
          props.onChange(newValue);
        }
      }
    });
  };

  // code autocomplete
  useEffect(() => {
    if (!autocompleteService.current && window.google) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }
    if (props.defaultValue && !inputValue) {
      fetch({ input: props.defaultValue }, (results) => {
        if (results) {
          setOptions([...results]);
          let newValue = results[0];
          if (newValue && newValue.place_id) {
            geocodeByPlaceId(newValue.place_id)
              .then((_results) => {
                if (_results.length > 0) {
                  newValue.coords = {
                    lat: _results[0].geometry.location.lat(),
                    lng: _results[0].geometry.location.lng(),
                  };
                  newValue.placeDetails = _results[0];
                }
                props.onChange(newValue);
              })
              .catch((err) => {});
          } else {
            props.onChange(newValue);
          }
        }
      });
    }
  }, [props.defaultValue, props.onChange, fetch, props, inputValue]);
  //  code ends

  useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results) => {
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  return (
    <Autocomplete
      noOptionsText={language.start_typing_the_address}
      style={props.style}
      getOptionLabel={(option) =>
        typeof option === "string" ? option : option.description
      }
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      inputValue={inputValue}
      value={props.value}
      onChange={(event, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
        if (newValue && newValue.place_id) {
          geocodeByPlaceId(newValue.place_id)
            .then((results) => {
              if (results.length > 0) {
                newValue.coords = {
                  lat: results[0].geometry.location.lat(),
                  lng: results[0].geometry.location.lng(),
                };
                newValue.placeDetails = results[0];
              }
              props.onChange(newValue);
            })
            .catch((error) => alert(language.google_places_error));
        } else {
          props.onChange(newValue);
        }
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={props.placeholder}
          variant={props.variant}
          fullWidth
          helperText={props.helperText}
        />
      )}
      renderOption={(option) => {
        const matches =
          option.structured_formatting.main_text_matched_substrings;
        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match) => [match.offset, match.offset + match.length])
        );

        return (
          <Grid container alignItems="center">
            <Grid item>
              <LocationOnIcon className={classes.icon} />
            </Grid>
            <Grid item xs>
              {parts.map((part, index) => (
                <span
                  key={index}
                  style={{ fontWeight: part.highlight ? 700 : 400 }}
                >
                  {part.text}
                </span>
              ))}

              <Typography variant="body2" color="textSecondary">
                {option.structured_formatting.secondary_text}
              </Typography>
            </Grid>
          </Grid>
        );
      }}
    />
  );
}

export default forwardRef(GoogleMapsAutoComplete);
