import React, { useState, useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import MUIDataTable from "mui-datatables";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Chip from "@mui/material/Chip";
import IconButton from "@mui/material/IconButton";
import CategoryIcon from "@mui/icons-material/Category";
import ConfirmationDialogRaw from "../components/ConfirmationDialogRaw";
import WrongLocationIcon from "@mui/icons-material/WrongLocation";
import { language } from "config";
import { FirebaseContext } from "common";
import SelectDriverModal from "components/SelectDriverModal/SelectDriverModal";
import ProductModal from "components/Products/ProductsModal";
import AlertDialog from "../components/AlertDialog";
import { Tooltip } from "@material-ui/core";

const BookingHistory = () => {
  const { api } = useContext(FirebaseContext);
  const { cancelBooking } = api;
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const [, setRole] = useState(null);
  const [data, setData] = useState([]);
  const bookinglistdata = useSelector((state) => state.bookinglistdata);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [selectedBooking, setSelectedBooking] = useState("");
  const [commonAlert, setCommonAlert] = useState({ open: false, msg: "" });

  useEffect(() => {
    if (bookinglistdata.bookings) {
      setData(bookinglistdata.bookings);
    } else {
      setData([]);
    }
  }, [bookinglistdata.bookings]);

  useEffect(() => {
    if (auth.info && auth.info.profile) {
      setRole(auth.info.profile.usertype);
    }
  }, [auth.info]);

  const onConfirmClose = (value) => {
    if (value) {
      dispatch(
        cancelBooking({
          reason: value,
          booking: selectedBooking,
        })
      );
    }
    setOpenConfirm(false);
  };

  const [driverForBooking, setDriverForBooking] = useState({
    bookings: [],
    modal: false,
  });

  const [productsModal, setProductsModal] = useState({
    visible: false,
    booking: null,
  });

  // const columns =  [
  //     { title: language.pickUpInstructions, field: 'pickUpInstructions' },
  //     { title: language.deliveryInstructions, field: 'deliveryInstructions' },
  //     { title: language.parcel_type, render: rowData => <span>{rowData.parcelTypeSelected?rowData.parcelTypeSelected.description + " (" + rowData.parcelTypeSelected.amount + ")":""}</span> },
  //     { title: language.parcel_option, render: rowData => <span>{rowData.optionSelected?rowData.optionSelected.description + " (" + rowData.optionSelected.amount + ")":""}</span> },
  //     { title: language.take_pickup_image,  field: 'pickup_image',render: rowData => rowData.pickup_image?<img alt='Pick Up' src={rowData.pickup_image} style={{width: 150}}/>:null, editable:'never'},
  //     { title: language.take_deliver_image,  field: 'deliver_image',render: rowData => rowData.deliver_image?<img alt='Deliver' src={rowData.deliver_image} style={{width: 150}}/>:null, editable:'never'},
  //     { title: language.vehicle_reg_no, field: 'vehicleNumber' },
  //     //{ title: language.cancellation_reason, field: 'reason'},
  //     { title: language.trip_start_time, field: 'trip_start_time' },
  //     { title: language.trip_end_time, field: 'trip_end_time' },
  //     { title: language.total_time, field: 'total_trip_time' },
  //     { title: language.distance, field: 'estimateDistance' },
  //     //{ title: language.trip_cost_driver_share, field: 'driver_share'},
  //     //{ title: language.convenience_fee, field: 'convenience_fees'},
  //     { title: language.discount_ammount, field: 'discount'},
  //     { title: language.Customer_paid, field: 'customer_paid'},
  //     //{ title: language.payment_mode, field: 'payment_mode'},
  //     //{ title: language.payment_gateway, field: 'gateway'},
  //     { title: language.cash_payment_amount, field: 'cashPaymentAmount'},
  //     { title: language.card_payment_amount, field: 'cardPaymentAmount'},
  //     { title: language.wallet_payment_amount, field: 'usedWalletMoney'},
  //     { title: language.settings_proof_pin_dash, field: 'otp'},
  //     { title: language.booking_id, field: 'id' }

  // ];

    //ACTIVATE THE CANCEL MODAL
    const handleDelete = () => {
      setOpenConfirm(true);
    };
  
    const handleCommonAlertClose = (e) => {
      e.preventDefault();
      setCommonAlert({ open: false, msg: "" });
    };

  const columns = [
    {
      name: "id",
      label: "Number",
      options: {
        // display: "excluded",
        customBodyRender: (value) => {
          return value;
        },
        filter: true,
        sort: true,
      },
    },
    {
      name: "bookingDate",
      label: language.booking_date,
      field: "bookingDate",
      options: {
        disablePadding: true,
        sort: true,
        filter: false,
      },
    },
    {
      name: "status",
      label: language.booking_status,
      field: "status",
      options: {
        customBodyRender: (value) => {
          // return value
          switch (value) {
            case "READY":
              return <Chip label="T0 SEND" color="warning" size="small" />;
            case "ACCEPTED":
              return <Chip label="ACCEPTED" color="info" size="small" />;
            case "ON_THE_WAY":
              return <Chip label="ON THE WAY" color="secondary" size="small" />;
            case "DELIVERED":
              return <Chip label="DELIVERED" color="success" size="small" />;
            case "COMPLETED":
              return <Chip label="COMPLETED" color="success" size="small" />;
            case "CANCELLED":
              return <Chip label="CANCELLED" color="error" size="small" />;
            case "ERROR":
              return <Chip label="ERROR" color="error" size="small" />;    
            default:
              return <Chip label="UNKNOWN" color="error" size="small" />;    
          }
        },
        hint: "Orders status",
        filter: true,
        sort: false,
      },
    },
    {
      name: "driver_name",
      label: language.assign_driver,
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "trip_start_time",
      label: language.trip_start_time,
      options: {
        disablePadding: true,
        filter: true,
        sort: true,
      },
    },
    {
      name: "trip_end_time",
      label: language.trip_end_time,
      options: {
        display: "excluded",
        disablePadding: true,
        filter: true,
        sort: true,
      },
    },
    {
      name: "total_trip_time",
      label: language.total_trip_time,
      options: {
        customBodyRender: (value) => {
          try {
            return (value / 60).toFixed(0) + "min";
          } catch (e) {
            return "ongoing";
          }
        },
        display: "excluded",
        disablePadding: true,
        filter: true,
        sort: true,
      },
    },
    {
      name: "requestedDrivers",
      label: language.number_of_pending_drivers,
      options: {
        customBodyRender: (value, tableMeta) => {
          return value && Object.keys(value).length
          ? Object.keys(value).length
          : "None";
        },
        display: "excluded",
        filter: false,
        sort: false,
      },
    },
    {
      name: "dropAddress",
      label: language.drop_address,
      options: {
      //   customBodyRender: (value, tableMeta, updateValue) => {
      //     return value;
      //   },
        filter: false,
        sort: false,
      },
    },
    {
      name: "customer_name",
      label: language.customer_name,
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "line_items",
      label: language.products,
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <IconButton
              onClick={() => {
                const rowData = tableMeta.rowData;
                const bookingId = rowData[0];
                const booking = data.find((b) => b.id === bookingId);
                if (booking) {
                  setProductsModal({
                    booking: booking,
                    visible: true,
                  });
                } else {
                  setCommonAlert({
                    open: true,
                    msg: "Something went wrong please retry",
                  });
                }
              }}
              color="secondary"
              aria-label="See products"
            >
              <CategoryIcon />
            </IconButton>
          );
        },
      },
    },
    {
      name: "total",
      label: language.total,
      options: {
        sort: true,
        filter: false,
      },
    },
    {
      name: "currency",
      label: language.currency,
      options: {
        display: "excluded",
        sort: true,
        filter: false,
      },
    },
    {
      name: "deliver_image",
      label: language.take_deliver_image,
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) =>
          !!value ? (
            <img alt="Deliver" src={value} style={{ width: 150 }} />
          ) : null,
      },
    },
    {
      name: "status",
      label: "Cancel",
      options: {
        hint: language.cancel_order_label_hint,
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          if (["READY", "ACCEPTED","ON_THE_WAY" ].includes(value)) {
            const handleClick = () => {
              const bookingId = tableMeta.rowData?.[0];
              const booking = data.find((b) => b.id === bookingId);
              if (!booking) {
                setCommonAlert({
                  open: false,
                  msg: "Something went wrong, please retry",
                });
              } else {
                setSelectedBooking(booking);
                handleDelete();
              }
            };
            return (
              <Chip
                size="small"
                color="error"
                label="Cancel"
                onClick={handleClick}
                onDelete={handleClick}
                deleteIcon={<WrongLocationIcon />}
              />
            );
          } else if(["ON_THE_WAY"].includes(value)) {
            return <Chip label={"ONGOING"} size="small" />;
          } else if(["DELIVERED", "COMPLETED"].includes(value)) {
            return <Chip label={"ORDER FINISHED"} size="small" />;
          } else if(["ERROR", "CANCELLED"].includes(value)) {
            return <Chip label={"NOT ACTIVE"} size="small" />;
          }
        },
      },
    },
    {
      name: "reason",
      label: language.cancel_reason,
      options: {
        display: "excluded",
        filter: false,
        sort: false,
      },
    },
    {
      name: "initError",
      label: language.error_reason,
      options: {
        display: "excluded",
        filter: false,
        sort: false,
      },
    },
  ];

  const options = {
    filter: true,
    onFilterChange: (changedColumn, filterList) => {},
    selectableRows: true,
    filterType: "dropdown",
    rowsPerPage: 50,
    rowsPerPageOptions: [50, 100, 200],
    expandableRows: true,
    expandableRowsOnClick: true,
    searchPlaceholder: "Search all deliveries",
    customToolbarSelect: (selectedRows, displayData) => {
      return (
        <div style={{ paddingInline: "20px" }}>
          <Tooltip title="Assign orders to drivers">
            <Chip
              label="ADD DRIVER TO SELECTED ORDERS"
              style={{ marginRight: "1200px" }}
              onClick={() => {
                const bookings = [];
                console.log(selectedRows);
                console.log(displayData);
                selectedRows.data
                  .map((d) => d.dataIndex)
                  .forEach((index) => {
                    const id = displayData?.[index]?.data?.[0];
                    if (id) {
                      const booking = data.find((b) => b.id === id);
                      if (booking) {
                        bookings.push(booking);
                      }
                    }
                  });
                  console.log(bookings);
                setDriverForBooking({
                  bookings: bookings,
                  modal: true,
                });
              }}
              color="warning"
              aria-label="Assign Driver"
            ></Chip>
          </Tooltip>
        </div>
      );
    },
    renderExpandableRow: (rowData) => {
      return (
        <>
          <tr>
            <td colSpan={12}>
              <TableContainer component={Paper}>
                <Table style={{ minWidth: "300" }} aria-label="simple table">
                  <TableHead></TableHead>
                  <TableBody>
                    {columns.map((col, index) => {
                      return (
                        <TableRow>
                          <TableCell>
                          {col.label}: {col.label === "Number" ? "#" : ""}{" "}
                            {rowData[index]}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </td>
          </tr>
        </>
      );
    },
  };

  return (
    <>
      <MUIDataTable
        title={"Current Orders - Select to assign drivers"}
        data={data}
        columns={columns}
        options={options}
      />
      <SelectDriverModal
        visible={driverForBooking.modal}
        bookings={driverForBooking.bookings}
        onClose={() => {
          setDriverForBooking({
            booking: [],
            modal: false,
          });
        }}
      />
      <ProductModal
        visible={productsModal.visible}
        booking={productsModal.booking}
        onClose={() =>
          setProductsModal({
            visible: false,
            booking: null,
          })
        }
      />
      <ConfirmationDialogRaw
        open={openConfirm}
        onClose={onConfirmClose}
        value={""}
      />
      <AlertDialog open={commonAlert.open} onClose={handleCommonAlertClose}>
        {commonAlert.msg}
      </AlertDialog>
    </>
  );
};

export default BookingHistory;
