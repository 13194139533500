import { useCallback, useContext } from "react";
import { FirebaseContext } from "common";

export default function useAddOrder() {
  const { bookingRef } = useContext(FirebaseContext);

  const addOrder = useCallback(
    async (order) => {
      if (order?.otp_secure) {
        order.otp = Math.floor(Math.random() * 90000) + 10000;
      }
      const ref = await bookingRef.push(order);
      return ref.key;
    },
    [bookingRef]
  );

  return addOrder;
}
