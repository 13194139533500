import React, { useState, useEffect, useContext, useRef } from "react";
import MaterialTable from 'material-table';
import { 
  features ,
  language
} from 'config';
import {
  Grid,
  Button,
  Typography,
  TextField,
  FormControlLabel,
  Modal,
} from "@material-ui/core";
import GoogleMapsAutoComplete from "../components/GoogleMapsAutoComplete";
import { useSelector, useDispatch } from "react-redux";
import AlertDialog from "../components/AlertDialog";
import { makeStyles } from "@material-ui/core/styles";
import UsersCombo from "../components/UsersCombo";
import { FirebaseContext } from "common";
import GridItem from "components/Grid/GridItem.js";
import DriverAutocomplete from "components/SelectDriverModal/DriverAutocomplete";
import Checkbox from "@material-ui/core/Checkbox";
import RegularButton from "components/CustomButtons/Button";
import useAddOrder from "hooks/useAddOrder";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  modal: {
    display: "flex",
    padding: theme.spacing(1),
    alignItems: "center",
    justifyContent: "center",
    overflow: "scroll",
    height: "100%",
  },
  paper: {
    width: 480,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  container: {
    zIndex: "12",
    color: "#F6F6FD",
    alignContent: "center",
  },
  title: {
    color: "#000000",
    fontFamily: "Poppins-Bold",
    fontSize: "22",
  },
  gridcontainer: {
    alignContent: "center",
  },
  items: {
    margin: 0,
    width: "100%",
  },
  input: {
    fontSize: 18,
    color: "#000000",
  },
  inputdimmed: {
    fontSize: 16,
    color: "#000000",
  },
  carphoto: {
    height: "18px",
    marginRight: "10px",
  },
  buttonStyle: {
    margin: 10,
    width: "100%",
    height: "100%",
    backgroundColor: "#35B368",
    color: "white",
    fontSize: 18,
  },
}));

const initialInstructionData = {
  deliveryPerson: "",
  deliveryPersonPhone: "",
  pickUpInstructions: "",
  deliveryInstructions: "",
  optionIndex: 0,
  optionSelected: null,
  total: 0,
  address_1: "",
  address_2: "",
};

export default function PhoneDelivery(props) {
  const { api } = useContext(FirebaseContext);
  const {
    addUser,
    editUser, 
    deleteUser,
    checkUserExists,
    clearEstimate,
    clearBooking,
    GetDateString,
  } = api;
  const dispatch = useDispatch();
  const classes = useStyles();
  const estimatedata = useSelector((state) => state.estimatedata);
  const bookingdata = useSelector((state) => state.bookingdata);
  const userdata = useSelector((state) => state.usersdata);
  const settings = useSelector((state) => state.settingsdata.settings);
  const [carType, setCarType] = useState(language.select_delivery);
  const [pickupAddress, setPickupAddress] = useState(null);
  const [dropAddress, setDropAddress] = useState(null);
  const [optionModalStatus, setOptionModalStatus] = useState(false);
  const [loading, setLoading] = useState(false);
  const [estimateModalStatus, setEstimateModalStatus] = useState(false);
  const [selectedCarDetails, setSelectedCarDetails] = useState(null);
  const [users, setUsers] = useState(null);
  const [commonAlert, setCommonAlert] = useState({ open: false, msg: "" });
  const [userCombo, setUserCombo] = useState(null);
  const [driverCombo, setDriverCombo] = useState(null);
  const [autoDispatch, setAutoDispatch] = useState(false);
  const [estimateRequested, setEstimateRequested] = useState(false);
  const [bookingType, setBookingType] = useState("Book Now");
  const rootRef = useRef(null);
  const [tempRoute, setTempRoute] = useState();
  const addOrder = useAddOrder();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const pickupPlaceInputRef = useRef();

  const [data, setData] = useState([]);
  const usersdata = useSelector(state => state.usersdata);

  const columns = [
    { title: language.account_approve,  field: 'approved', type:'boolean'},
    { title: language.first_name, field: 'firstName'},
    { title: language.last_name, field: 'lastName'},
    // { title: "PickUp Address", field: 'startAddress', },
    { title: language.email, field: 'email', editable:'onAdd',render: rowData => features.AdminEdits ? rowData.email : "Hidden for Demo"},
    { title: language.mobile, field: 'mobile', editable:'onAdd',render: rowData => features.AdminEdits ? rowData.mobile : "Hidden for Demo"},
  ];

  useEffect(()=>{
    if(usersdata.users){
      setData(usersdata.users.filter(user => user.usertype ==='dispatcher'));
    }else{
      setData([]);
    }
  },[usersdata.users]);

  useEffect(() => {
    if (userCombo?.startAddress) {
      pickupPlaceInputRef.current?.searchForFirstOption?.(
        userCombo.startAddress
      );
    }
  }, [userCombo]);

  useEffect(() => {
    setAutoDispatch(!driverCombo);
  }, [driverCombo]);

  const [instructionData, setInstructionData] = useState(
    initialInstructionData
  );

  const handleChange = (e) => {

    if (e.target.name === "optionIndex") {
      setInstructionData({
        ...instructionData,
        optionIndex: parseInt(e.target.value),
        optionSelected: selectedCarDetails.options[e.target.value],
      });
    } else {
      setInstructionData({
        ...instructionData,
        [e.target.name]: e.target.value,
      });
    }
  };

  const [selectedDate, setSelectedDate] = useState(GetDateString());

  // const handleCarSelect = (event) => {
  //   setCarType(event.target.value);
  //   let carDetails = null;
  //   for (let i = 0; i < cartypes.length; i++) {
  //     if (cartypes[i].name === event.target.value) {
  //       carDetails = cartypes[i];
  //       let instObj = { ...instructionData };
  //       // if (Array.isArray(cartypes[i].parcelTypes)) {
  //       //   instObj.parcelTypeSelected = cartypes[i].parcelTypes[0];
  //       //   instObj.parcelTypeIndex = 0;
  //       // }
  //       if (Array.isArray(cartypes[i].options)) {
  //         instObj.optionSelected = cartypes[i].options[0];
  //         instObj.optionIndex = 0;
  //       }
  //       setInstructionData(instObj);
  //     }
  //   }
  //   setSelectedCarDetails(carDetails);
  // };

  // const handleBookTypeSelect = (event) => {
  //   setBookingType(event.target.value);
  //   if (bookingType === "Book Later") {
  //     setSelectedDate(GetDateString());
  //   }
  // };

  // const onDateChange = (event) => {
  //   setSelectedDate(event.target.value);
  // };

  useEffect(() => {
    if (estimatedata.estimate && estimateRequested) {
      setEstimateRequested(false);
      setEstimateModalStatus(true);
    }
    if (userdata.users) {
      let arr = [];
      for (let i = 0; i < userdata.users.length; i++) {
        let user = userdata.users[i];
        if (user.usertype === "dispatcher") {
          arr.push({
            firstName: user.firstName,
            lastName: user.lastName,
            mobile: user.mobile,
            email: user.email,
            uid: user.id,
            desc:
              user.firstName +
              " " +
              user.lastName +
              " (" +
              (features.AdminEdits ? user.mobile : "Hidden") +
              ") " +
              (features.AdminEdits ? user.email : "Hidden"),
            pushToken: user.pushToken,
            startAddress: user?.startAddress || "",
          });
        }
      }
      setUsers(arr);
    }
  }, [estimatedata.estimate, userdata.users, estimateRequested]);

  const handleGetOptions = (e) => {
    e.preventDefault();
    if (!dropAddress) {
      enqueueSnackbar("Please select drop address", { variant: "error" });
    } else {
      setEstimateModalStatus(true);
    }
  };

  const confirmBooking = async (e) => {
    try {
      setLoading(true);
      e.preventDefault();

      const drop = {
        add: dropAddress.description,
        lat: dropAddress.coords.lat,
        lng: dropAddress.coords.lng,
      };

      if (!userCombo) {
        if (!instructionData.deliveryPerson) {
          enqueueSnackbar("Please enter a delivery person", {
            variant: "error",
          });
          return;
        }

        if (
          !/^\+[1-9]\d{1,14}$/.test(instructionData.deliveryPersonPhone) ||
          instructionData.deliveryPersonPhone.length < 10
        ) {
          enqueueSnackbar("Please enter valid phone number", {
            variant: "error",
          });
          return;
        }
      }

      if (!/^[0-9]\d*(\.\d+)?$/.test(instructionData.total)) {
        enqueueSnackbar("Please enter valid amount", {
          variant: "error",
        });
        return;
      }

      setEstimateModalStatus(false);

      let bookingObject = {
        drop: drop,
        initialized: false,
        manualDispatch: !autoDispatch,
        booking_type_web: true,
        otp_secure: settings?.otp_secure,
        pickUpInstructions: instructionData.pickUpInstructions,
        deliveryInstructions: instructionData.deliveryInstructions,
        total: instructionData.total,
        address_1: instructionData.address_1,
        address_2: instructionData.address_2,
      };

      if (userCombo) {
        bookingObject.customer_name = `${userCombo.firstName} ${userCombo.lastName}`;
        bookingObject.customer_contact = userCombo.mobile;
      } else if (
        instructionData.deliveryPerson &&
        instructionData.deliveryPersonPhone
      ) {
        bookingObject.customer_name = instructionData.deliveryPerson;
        bookingObject.customer_contact = instructionData.deliveryPersonPhone;
      }

      if (driverCombo && driverCombo?.id) {
        bookingObject.requestedDrivers = {
          [driverCombo.id]: true,
        };
      }
      await addOrder(bookingObject);
      setLoading(false);
      enqueueSnackbar("Order added successfully", { variant: "success" });
      clearForm();

    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
      // display error
    }
  };

  const handleOptionModalClose = (e) => {
    e.preventDefault();
    setOptionModalStatus(false);
  };

  const handleEstimateModalClose = (e) => {
    e.preventDefault();
    setEstimateModalStatus(false);
    dispatch(clearEstimate());
    setEstimateRequested(false);
  };

  const handleEstimateErrorClose = (e) => {
    e.preventDefault();
    dispatch(clearEstimate());
    setEstimateRequested(false);
  };

  const handleBookingAlertClose = (e) => {
    e.preventDefault();
    dispatch(clearBooking());
    dispatch(clearEstimate());
    clearForm();
  };

  const clearForm = () => {
    setInstructionData(initialInstructionData);
    setUserCombo(null);
    setPickupAddress(null);
    setDropAddress(null);
    setSelectedCarDetails(null);
    setDriverCombo(null);
    setCarType(language.select_delivery);
    setBookingType(language.book_now);
    setEstimateRequested(false);
  };

  const handleBookingErrorClose = (e) => {
    e.preventDefault();
    dispatch(clearBooking());
    setEstimateRequested(false);
  };

  const handleCommonAlertClose = (e) => {
    e.preventDefault();
    setCommonAlert({ open: false, msg: "" });
  };

  return (
    <>
    <div className={classes.container} ref={rootRef}>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography component="h1" variant="h5" className={classes.title}>
            {"Custom delivery"}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {users ? (
              <UsersCombo
                className={classes.items}
                placeholder={language.select_user}
                users={users}
                value={userCombo}
                onChange={(event, newValue) => {
                  setUserCombo(newValue);
                }}
              />
            ) : null}
          </Grid>
          <Grid item xs={12}>
            <GoogleMapsAutoComplete
              placeholder={language.drop_location}
              variant={"outlined"}
              value={dropAddress}
              className={classes.items}
              onChange={(value) => {
                setDropAddress(value);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              color="success"
              variant="outlined"
              // margin="normal"
              fullWidth
              id="address_1"
              label={"House - Building or Flat number, Floor number"}
              name="address_1"
              autoComplete="address_1"
              onChange={handleChange}
              value={instructionData.address_1}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              color="success"
              variant="outlined"
              // margin="normal"
              fullWidth
              id="address_2"
              label={"State, Area"}
              name="address_2"
              autoComplete="address_2"
              onChange={handleChange}
              value={instructionData.address_2}
            />
          </Grid>
          <Grid item xs={12}>
            {users ? (
              <DriverAutocomplete
                selected={driverCombo}
                onChange={setDriverCombo}
              />
            ) : null}
          </Grid>
          <GridItem
            xs={12}
            style={{ display: "flex", flexDirection: "column", paddingTop: 30 }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={autoDispatch}
                  onChange={(e) => {
                    setAutoDispatch(e.target.checked);
                  }}
                  color="warning"
                />
              }
              label="Auto dispatch to drivers?"
            />
          </GridItem>
          <GridItem
            xs={12}
            style={{ display: "flex", flexDirection: "column", paddingTop: 30 }}
          >
          </GridItem>
          <Grid item xs={12} sm={12}>
            <Button
              size="large"
              onClick={handleGetOptions}
              variant="contained"
              className={classes.buttonStyle}
            >
              {language.phone_booking_btn}
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        open={estimateModalStatus}
        onClose={handleEstimateModalClose}
        className={classes.modal}
        container={() => rootRef.current}
      >
        <Grid container spacing={1} className={classes.paper}>
          <Typography
            component="h1"
            variant="h5"
            className={classes.title}
            style={{ marginTop: 15 }}
          >
            {"ADD PRODUCTS - NOTES"}
          </Typography>
          {!userCombo && (
            <Grid item xs={12}>
              <TextField
                color="warning"
                variant="standard"
                margin="normal"
                fullWidth
                id="deliveryPerson"
                label={language.deliveryPerson}
                name="deliveryPerson"
                autoComplete="deliveryPerson"
                onChange={handleChange}
                value={instructionData.deliveryPerson}
                autoFocus
              />
            </Grid>
          )}
          {!userCombo && (
            <Grid item xs={12}>
              <TextField
                color="success"
                variant="standard"
                margin="normal"
                fullWidth
                id="deliveryPersonPhone"
                label={language.deliveryPersonPhone}
                name="deliveryPersonPhone"
                autoComplete="deliveryPersonPhone"
                onChange={handleChange}
                value={instructionData.deliveryPersonPhone}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <TextField
              color="success"
              variant="outlined"
              multiline
              rows={10}
              margin="normal"
              fullWidth
              id="pickUpInstructions"
              label={"Notes and Products"}
              name="pickUpInstructions"
              autoComplete="pickUpInstructions"
              onChange={handleChange}
              value={instructionData.pickUpInstructions}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              color="success"
              variant="outlined"
              margin="normal"
              fullWidth
              id="deliveryInstructions"
              label={"Any help instructions for the driver"}
              name="deliveryInstructions"
              autoComplete="deliveryInstructions"
              onChange={handleChange}
              value={instructionData.deliveryInstructions}
            />
          </Grid>
          
          <Grid item xs={12}>
            <TextField
              color="success"
              variant="outlined"
              margin="normal"
              fullWidth
              id="total"
              label={"Total to be paid"}
              name="total"
              autoComplete="total"
              onChange={handleChange}
              value={instructionData.total}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <RegularButton
              onClick={confirmBooking}
              variant="contained"
              color="warning"
              style={{ marginLeft: 10 }}
            >
              {language.book_now}
            </RegularButton>
            <Button
              simple
              onClick={handleEstimateModalClose}
              variant="text"
              color="warning"
            >
              {language.cancel}
            </Button>
          </Grid>
        </Grid>
      </Modal>
      <AlertDialog
        open={bookingdata.booking ? true : false}
        onClose={handleBookingAlertClose}
      >
        {bookingdata.booking
          ? language.booking_success + bookingdata.booking.booking_id
          : null}
      </AlertDialog>
      <AlertDialog
        open={bookingdata.error.flag}
        onClose={handleBookingErrorClose}
      >
        {bookingdata.error.msg}
      </AlertDialog>
      <AlertDialog
        open={estimatedata.error.flag}
        onClose={handleEstimateErrorClose}
      >
        {estimatedata.error.msg}
      </AlertDialog>
      <AlertDialog open={commonAlert.open} onClose={handleCommonAlertClose}>
        {commonAlert.msg}
      </AlertDialog>
    </div>
        <br>
    </br>
    <br>
    </br>
    <hr></hr>
    <br>
    </br>
    <div className={classes.container}>
<MaterialTable
  title={"ADD NEW CUSTOMER"}
  columns={columns}
  data={data}
  options={{
    paging:true,
        pageSize:5,       // make initial page size
        emptyRowsWhenPaging: false,   // To avoid of having empty rows
        pageSizeOptions:[5, 20, 100],    // rows selection options
    exportButton: features.AdminEdits,
    sorting: true,
    searchFieldAlignment:'left',
    headerStyle: {
      backgroundColor: 'black',
      color: '#fff',
      fontWeight:"bold"
    },
  }}
  editable={{
    onRowAdd: newData =>
      new Promise((resolve,reject) => {
        setTimeout(() => {
          checkUserExists(newData).then((res) => {
            if (res.users && res.users.length > 0) {
              alert(language.user_exists);
              reject();
            }
            else if(res.error){
              alert(language.email_or_mobile_issue);
              reject();
            }
            else{
              newData['createdByAdmin'] = true;
              newData['usertype'] = 'dispatcher';
              newData['createdAt'] = new Date().toISOString();
              newData['referralId'] = newData.firstName.toLowerCase() + Math.floor(1000 + Math.random() * 9000).toString();
              dispatch(addUser(newData));
              resolve();
            }
          });
        }, 600);
      }),
    onRowUpdate: (newData, oldData) =>
      new Promise(resolve => {
        setTimeout(() => {
          resolve();
          dispatch(editUser(oldData.id,newData));
        }, 600);
      }),
    onRowDelete: oldData =>
      features.AdminEdits?
      new Promise(resolve => {
        setTimeout(() => {
          resolve();
          dispatch(deleteUser(oldData.id));
        }, 600);
      })
      :
      new Promise(resolve => {
        setTimeout(() => {
          resolve();
          alert(language.demo_mode);
        }, 600);
      })
      , 
  }}
/>
</div>
</>
  );
}
