import React, { useState, useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import MUIDataTable from "mui-datatables";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Chip from "@mui/material/Chip";
import { language } from "config";
import { FirebaseContext } from "common";
import ProductModal from "components/Products/ProductsModal";

const DeliveryStats = () => {
    const { api } = useContext(FirebaseContext);
    const auth = useSelector((state) => state.auth);
    const [, setRole] = useState(null);
    const [data, setData] = useState([]);
    const bookinglistdata = useSelector((state) => state.bookinglistdata);
  
    useEffect(() => {
      if (Array.isArray(bookinglistdata.bookings)) {
        const readyOrders = bookinglistdata.bookings.filter((order) =>
        ["DELIVERED", "COMPLETED"].includes(order.status)
      );
        setData(readyOrders);
      } else {
        setData([]);
      }
    }, [bookinglistdata.bookings]);
  
    useEffect(() => {
      if (auth.info && auth.info.profile) {
        setRole(auth.info.profile.usertype);
      }
    }, [auth.info]);
  
    // const onConfirmClose = (value) => {
    //   if (value) {
    //     dispatch(
    //       cancelBooking({
    //         reason: value,
    //         booking: selectedBooking,
    //       })
    //     );
    //   }
    //   setOpenConfirm(false);
    // };

    // const [driverForBooking, setDriverForBooking] = useState({
    //   bookings: [],
    //   modal: false,
    // });

    const [productsModal, setProductsModal] = useState({
      visible: false,
      booking: null,
    });
  
    // const handleCommonAlertClose = (e) => {
    //   e.preventDefault();
    //   setCommonAlert({ open: false, msg: "" });
    // };
  
    const columns = [
      {
        name: "id",
        label: "Number",
        options: {
          // display: "excluded",
          customBodyRender: (value) => {
            return value;
          },
          filter: true,
          sort: true,
        },
      },
      {
        name: "bookingDate",
        label: language.booking_date,
        field: "bookingDate",
        options: {
          disablePadding: true,
          sort: true,
          filter: false,
        },
      },
      {
        name: "driver_name",
        label: language.assign_driver,
        options: {
          filter: true,
          sort: false,
        },
      },
      {
        name: "trip_start_time",
        label: language.trip_start_time,
        options: {
          disablePadding: true,
          filter: true,
          sort: true,
        },
      },
      {
        name: "trip_end_time",
        label: language.trip_end_time,
        options: {
          disablePadding: true,
          filter: true,
          sort: true,
        },
      },
      {
        name: "total_trip_time",
        label: language.total_trip_time,
        options: {
          customBodyRender: (value) => {
            return (value / 60).toFixed(0) + "min";
          },
          disablePadding: true,
          filter: true,
          sort: true,
        },
      },
      {
        name: "customer_name",
        label: language.customer_name,
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "status",
        label: language.booking_status,
        field: "status",
        options: {
          customBodyRender: (value) => {
            // return value
            switch (value) {
              case "READY":
                return <Chip label="T0 SEND" color="warning" size="small" />;
              case "ACCEPTED":
                return <Chip label="ACCEPTED" color="info" size="small" />;
              case "ON_THE_WAY":
                return <Chip label="ON THE WAY" color="secondary" size="small" />;
              case "DELIVERED":
                return <Chip label="DELIVERED" color="success" size="small" />;
              case "COMPLETED":
                return <Chip label="COMPLETED" color="success" size="small" />;
              case "CANCELLED":
                return <Chip label="CANCELLED" color="error" size="small" />;
              case "ERROR":
                return <Chip label="ERROR" color="error" size="small" />;    
              default:
                return <Chip label="UNKNOWN" color="error" size="small" />;    
            }
          },
          hint: "Orders status",
          filter: true,
          sort: false,
        },
      },
    ];
  
    const options = {
      filter: true,
      onFilterChange: (changedColumn, filterList) => {},
      selectableRows: false,
      filterType: "dropdown",
      rowsPerPage: 50,
      rowsPerPageOptions: [50, 100, 200],
      expandableRows: true,
      expandableRowsOnClick: true,
      searchPlaceholder: "Search Completed deliveries",
      customToolbarSelect: (selectedRows, displayData) => {
        return <div style={{ paddingInline: "20px" }}></div>;
      },
      renderExpandableRow: (rowData) => {
        return (
          <>
            <tr>
              <td colSpan={12}>
                <TableContainer component={Paper}>
                  <Table style={{ minWidth: "300" }} aria-label="simple table">
                    <TableHead></TableHead>
                    <TableBody>
                      {columns.map((col, index) => {
                        return (
                          <TableRow>
                            <TableCell>
                            {col.label}: {col.label === "Number" ? "#" : ""}{" "}
                            {rowData[index]}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </td>
            </tr>
          </>
        );
      },
    };
  
    return (
      <>
        <MUIDataTable
          title={"Delivery-Drivers Stats"}
          data={data}
          columns={columns}
          options={options}
        />
        <ProductModal
          visible={productsModal.visible}
          booking={productsModal.booking}
          onClose={() =>
            setProductsModal({
              visible: false,
              booking: null,
            })
          }
        />
        {/* <ConfirmationDialogRaw
          open={openConfirm}
          onClose={onConfirmClose}
          value={""}
        />
        <AlertDialog open={commonAlert.open} onClose={handleCommonAlertClose}>
          {commonAlert.msg}
        </AlertDialog> */}
      </>
    );
  };  

export default DeliveryStats;
