import React, { useEffect, useState, useContext } from 'react'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { useSelector } from "react-redux";
import { FirebaseContext } from 'common';
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert';
import {language} from 'config';

function SelectDriverModal(props) {
    const {visible, onClose, bookings} = props;
    const { api } = useContext(FirebaseContext);
    const {
        attachBookingsToDriver
    } = api;
    const usersdata = useSelector(state => state.usersdata);
    const [snackbar, setSnackbar] = useState({
        visible: false,
        text: "",
        severity: ""
    });
    const [loading, setLoading] = useState(false);
    const auth = useSelector(state => state.auth);
    const [drivers, setDrivers] = useState([]);
    const [selected, setSelected] = useState(null);
    
    useEffect(()=>{
        if(usersdata.users){
            setDrivers(usersdata.users.filter(user => user.usertype ==='driver' && ((user.fleetadmin === auth.info.uid && auth.info.profile.usertype === 'fleetadmin')|| auth.info.profile.usertype === 'admin')));
        }else{
          setDrivers([]);
        }
      },[usersdata.users,auth.info.profile.usertype,auth.info.uid]);

      const _onClose = () => {
        setSelected(null)
        onClose()
      }
      const attach = async () => {
        try {
            setLoading(true)
           await attachBookingsToDriver(selected, bookings)
           _onClose()
           setSnackbar({
            visible: true,
            severity: "success",
            text: language.booking_attached
           })
        } catch (error) {
          console.log(error)
                setSnackbar({
                    visible: true,
                    severity: "error",
                    text: language.failed_to_attach_booking
                   })
        } finally {
            setLoading(false)
        }
      }
  return (
    <>
    <Dialog fullWidth maxWidth="sm" open={visible} onClose={_onClose}>
        <DialogTitle id="form-dialog-title">{language.Select_a_driver}</DialogTitle>
        <DialogContent>
          <Autocomplete 
            options={drivers}
            getOptionLabel={(option) => `${option.firstName} ${option.lastName} ${option?.mobile || option?.email}`}
            style={{ width: "100%" }}
            renderInput={(params) => <TextField disabled={!drivers || (Array.isArray(drivers) && drivers.length === 0)} {...params} label={language.search_driver} variant="outlined" />}
            value={selected}
            onChange={(e, newValue) => {
                setSelected(newValue)
            }}
            />
        </DialogContent>
        <DialogActions>
          <Button onClick={_onClose} color="primary">
            {language.cancel}
          </Button>
          <Button  disabled={!selected || loading} onClick={attach} color="primary">
            {language.attach}
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar open={snackbar.visible} anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        severity={snackbar.severity}
        autoHideDuration={1000}
        onClose={() => {
            setSnackbar({
                visible: false,
                severity: '',
                text: ""
            })
        }}
        >
            <MuiAlert variant="filled" onClose={(e, r) => {
                if (r === 'clickaway') {
                    return;
                }
                setSnackbar({
                    visible: false,
                    severity: '',
                    text: ""
                })
            }} severity={snackbar.severity} >
                {snackbar.text}
            </MuiAlert>
      </Snackbar>
    </>
  )
}

export default SelectDriverModal