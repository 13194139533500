import { container, title } from "assets/jss/material-kit-react.js";

const landingPageStyle = {
  container: {
    zIndex: "12",
    color: "#35B368",
    backgroundColor:"transparent",
    ...container,
    alignContent:'center'
    
  },
  title: {
    ...title,
    display: "inline-block",
    position: "relative",
    marginTop: "0px",
    minHeight: "0px",
    color: "#35B368",
    textDecoration: "none"
  },

  pageHeader: {
    minHeight: "120vh",
    height: "auto",
    display: "inherit",
    position: "relative",
    margin: "0",
    padding: "0",
    border: "0",
    alignItems: "center",
    // "&:before": {
    //   background: "rgba(0, 0, 0, 0.5)"
    // },
    // "&:before,&:after": {
    //   position: "absolute",
    //   zIndex: "1",
    //   width: "100%",
    //   height: "100%",
    //   display: "block",
    //   left: "0",
    //   top: "0",
    //   content: '""'
    // },
    "& footer li a,& footer li a:hover,& footer li a:active": {
      color: "#000000"
    },
    "& footer": {
      position: "absolute",
      bottom: "30",
      width: "100%"
    }
  },
  items:{
    margin:0,
    width:'100%'
  },
  menuItems:{
    width:'100%',
  },
  carphoto:{
    height: '22px',
    marginRight:'10px'
  }

};

export default landingPageStyle;
