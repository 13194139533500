import React, { useState, useEffect, useContext, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";

import {
  Select,
  MenuItem,
  TextField,
  FormControlLabel,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  Modal,
  Grid,
  Typography,
  //colors
} from "@material-ui/core";
import GoogleMapsAutoComplete from "../components/GoogleMapsAutoComplete";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { useSelector, useDispatch } from "react-redux";
import AlertDialog from "../components/AlertDialog";
import { language } from "config";
import { FirebaseContext } from "common";


const useStyles = makeStyles((theme) => ({
  ...styles,
  modal: {
    display: "flex",
    padding: theme.spacing(1),
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    width: 440,
    backgroundColor: "#F2F1F0",
    border: "0px solid #000000",
    //borderRadius:'5',
    boxShadow: theme.shadows[3],
    padding: theme.spacing(3, 5, 3),
  },
  container: {
    zIndex: "12",
    alignContent: "center",
  },
  gridcontainer: {
    alignContent: "center",
  },
  items: {
    margin: 0,
    width: "100%",
  },
}));

export default function NewDelivery(props) {
  const { api } = useContext(FirebaseContext);
  const {
    getEstimate,
    clearEstimate,
    addBooking,
    clearBooking,
    MinutesPassed,
    GetDateString,
  } = api;
  const classes = useStyles();
  const dispatch = useDispatch();
  //const { ...rest } = props;
  const cartypes = useSelector((state) => state.cartypes.cars);
  const estimatedata = useSelector((state) => state.estimatedata);
  const bookingdata = useSelector((state) => state.bookingdata);
  const settings = useSelector((state) => state.settingsdata.settings);
  const [carType, setCarType] = useState(language.select_delivery);
  const [pickupAddress, setPickupAddress] = useState(null);
  const [dropAddress, setDropAddress] = useState(null);
  const [optionModalStatus, setOptionModalStatus] = useState(false);
  const [estimateModalStatus, setEstimateModalStatus] = useState(false);
  const [estimateRequested, setEstimateRequested] = useState(false);
  const [selectedCarDetails, setSelectedCarDetails] = useState(null);
  const auth = useSelector((state) => state.auth);
  const [commonAlert, setCommonAlert] = useState({ open: false, msg: "" });
  const [bookingType, setBookingType] = useState("Book Now");
  const [role, setRole] = useState(null);
  const [selectedDate, setSelectedDate] = React.useState(GetDateString());
  const pickupAddressComboRef = useRef();
  const rootRef = useRef(null);
  const [tempRoute, setTempRoute] = useState();
  const [instructionData, setInstructionData] = useState({
    deliveryPerson: "",
    deliveryPersonPhone: "",
    pickUpInstructions: "",
    deliveryInstructions: "",
    parcelTypeIndex: 0,
    optionIndex: 0,
    parcelTypeSelected: null,
    optionSelected: null
  });

  const handleChange = (e) => {
if (e.target.name === "optionIndex") {
      setInstructionData({
        ...instructionData,
        optionIndex: parseInt(e.target.value),
        optionSelected: selectedCarDetails.options[e.target.value],
      });
    } else {
      setInstructionData({
        ...instructionData,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleCarSelect = (event) => {
    setCarType(event.target.value);
    let carDetails = null;
    for (let i = 0; i < cartypes.length; i++) {
      if (cartypes[i].name === event.target.value) {
        carDetails = cartypes[i];
        let instObj = { ...instructionData };
        if (Array.isArray(cartypes[i].parcelTypes)) {
          instObj.parcelTypeSelected = cartypes[i].parcelTypes[0];
          instObj.parcelTypeIndex = 0;
        }
        if (Array.isArray(cartypes[i].options)) {
          instObj.optionSelected = cartypes[i].options[0];
          instObj.optionIndex = 0;
        }
        setInstructionData(instObj);
      }
    }
    setSelectedCarDetails(carDetails);
  };

  const handleBookTypeSelect = (event) => {
    setBookingType(event.target.value);
    if (bookingType === "Book Later") {
      setSelectedDate(GetDateString());
    }
  };

  const onDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  useEffect(() => {
    if (estimatedata.estimate && estimateRequested) {
      setEstimateModalStatus(true);
    }
    if (auth.info && auth.info.profile) {
      setRole(auth.info.profile.usertype);
    }
  }, [estimatedata.estimate, auth.info, estimateRequested]);

  const handleGetOptions = (e) => {
    e.preventDefault();
    setEstimateRequested(true);
    if (auth.info) {
      if (pickupAddress && dropAddress && selectedCarDetails) {
      
        const directionService = new window.google.maps.DirectionsService();
        directionService.route(
          {
            origin: new window.google.maps.LatLng(
              pickupAddress.coords.lat,
              pickupAddress.coords.lng
            ),
            destination: new window.google.maps.LatLng(
              dropAddress.coords.lat,
              dropAddress.coords.lng
            ),
            travelMode: window.google.maps.TravelMode.DRIVING,
          },
          (result, status) => {
            if (status === window.google.maps.DirectionsStatus.OK) {
              const route = {
                distance: result.routes[0].legs[0].distance.value / 1000,
                duration: result.routes[0].legs[0].duration.value,
                polylinePoints: result.routes[0].overview_polyline,
              };
              setTempRoute(route);
              if (bookingType === "Book Now") {
                if (
                  Array.isArray(selectedCarDetails.options) ||
                  Array.isArray(selectedCarDetails.parcelTypes)
                ) {
                  setOptionModalStatus(true);
                } else {
                  let estimateRequest = {
                    pickup: pickupAddress,
                    drop: dropAddress,
                    carDetails: selectedCarDetails,
                    instructionData: instructionData,
                    routeDetails: route,
                  };
                  dispatch(getEstimate(estimateRequest));
                }
              } else {
                if (bookingType === "Book Later" && selectedDate) {
                  if (MinutesPassed(selectedDate) >= 15) {
                    if (
                      Array.isArray(selectedCarDetails.options) ||
                      Array.isArray(selectedCarDetails.parcelTypes)
                    ) {
                      setOptionModalStatus(true);
                    } else {
                      let estimateRequest = {
                        pickup: pickupAddress,
                        drop: dropAddress,
                        carDetails: selectedCarDetails,
                        instructionData: instructionData,
                        routeDetails: route,
                      };
                      dispatch(getEstimate(estimateRequest));
                    }
                  } else {
                    setCommonAlert({
                      open: true,
                      msg: language.past_booking_error,
                    });
                  }
                } else {
                  setCommonAlert({ open: true, msg: language.select_proper });
                }
              }
            } else {
              setCommonAlert({
                open: true,
                msg: language.place_to_coords_error,
              });
            }
          }
        );
      } else {
        setCommonAlert({ open: true, msg: language.select_proper });
      }
    } else {
      setCommonAlert({ open: true, msg: language.must_login });
    }
  };

  const handleGetEstimate = (e) => {
    e.preventDefault();
    setOptionModalStatus(false);
    let estimateRequest = {
      pickup: pickupAddress,
      drop: dropAddress,
      carDetails: selectedCarDetails,
      instructionData: instructionData,
      routeDetails: tempRoute,
    };
    dispatch(getEstimate(estimateRequest));
  };

  const confirmBooking = (e) => {
    e.preventDefault();
    if (instructionData.deliveryPerson && instructionData.deliveryPersonPhone) {
      setEstimateModalStatus(false);
      let bookingObject = {
        pickup: pickupAddress,
        drop: dropAddress,
        carDetails: selectedCarDetails,
        userDetails: auth.info,
        estimate: estimatedata.estimate,
        instructionData: instructionData,
        tripdate: new Date(selectedDate).toString(),
        bookLater: bookingType === "Book Later" ? true : false,
        settings: settings,
        booking_type_web: true,
      };
      dispatch(addBooking(bookingObject));
    } else {
      alert(language.deliveryDetailMissing);
    }
  };

  const handleOptionModalClose = (e) => {
    e.preventDefault();
    setOptionModalStatus(false);
  };

  const handleEstimateModalClose = (e) => {
    e.preventDefault();
    setEstimateModalStatus(false);
    dispatch(clearEstimate());
    setEstimateRequested(false);
  };

  const handleEstimateErrorClose = (e) => {
    e.preventDefault();
    dispatch(clearEstimate());
    setEstimateRequested(false);
  };

  const handleBookingAlertClose = (e) => {
    e.preventDefault();
    dispatch(clearBooking());
    dispatch(clearEstimate());
    props.history.push("/userbookings");
  };

  const handleBookingErrorClose = (e) => {
    e.preventDefault();
    dispatch(clearBooking());
    setEstimateRequested(false);
  };

  const handleCommonAlertClose = (e) => {
    e.preventDefault();
    setCommonAlert({ open: false, msg: "" });
  };

  const startAddress = auth?.info?.profile?.startAddress;

  useEffect(() => {
    if (startAddress) {
      pickupAddressComboRef.current?.searchForFirstOption?.(startAddress);
    }
  }, [startAddress]);

  return (
    <div>
      <div>
        {(cartypes && !role) ||
        (cartypes &&
          (role === "dispatcher" ||
            role === "driver" ||
            role === "fleetadmin")) ? (
          <div className={classes.container}>
            <GridContainer spacing={2}>
              <Grid item xs={12}>
                <Typography
                  component="h1"
                  variant="h5"
                  className={classes.title}
                >
                  {language.newdelivery}
                </Typography>
              </Grid>
              <GridItem xs={12} sm={12} md={6} lg={6}>
                <GoogleMapsAutoComplete
                  ref={pickupAddressComboRef}
                  placeholder={language.pickup_location}
                  variant={"standard"}
                  value={pickupAddress}
                  // defaultValue={auth?.info?.profile?.startAddress}
                  onChange={(value) => {
                    setPickupAddress(value);
                  }}
                />
              </GridItem>
            </GridContainer>
            <GridContainer spacing={2}>
              <GridItem xs={12} sm={12} md={6} lg={6}>
                <GoogleMapsAutoComplete
                  placeholder={language.drop_location}
                  variant={"standard"}
                  value={dropAddress}
                  onChange={(value) => {
                    setDropAddress(value);
                  }}
                />
              </GridItem>
            </GridContainer>
            <GridContainer spacing={2}>
              <GridItem xs={12} sm={12} md={6} lg={6}>
                <FormControl variant="outlined" style={{ width: "100%" }}>
                  <Select
                    id="car-type-native"
                    value={carType}
                    onChange={handleCarSelect}
                    className={
                      carType === language.select_delivery
                        ? classes.inputdimmed
                        : classes.input
                    }
                  >
                    <MenuItem
                      value={language.select_delivery}
                      key={language.select_delivery}
                    >
                      {language.select_delivery}
                    </MenuItem>
                    {cartypes.map((car) => (
                      <MenuItem key={car.name} value={car.name}>
                        <img
                          src={car.image}
                          className={classes.carphoto}
                          alt="parcel types"
                        />
                        {car.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </GridItem>
            </GridContainer>
            <GridContainer spacing={2}>
              <GridItem xs={12} sm={12} md={6} lg={6}>
                <FormControl variant="outlined" style={{ width: "100%" }}>
                  <Select
                    id="booking-type-native"
                    value={bookingType}
                    onChange={handleBookTypeSelect}
                    className={classes.input}
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem key={"Book Now"} value={"Book Now"}>
                      {language.book_now}
                    </MenuItem>
                    <MenuItem key={"Book Later"} value={"Book Later"}>
                      {language.book_later}
                    </MenuItem>
                  </Select>
                </FormControl>
              </GridItem>
            </GridContainer>
            <GridContainer spacing={2}>
              {bookingType === "Book Later" ? (
                <GridItem xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    id="datetime-local"
                    label={language.booking_date_time}
                    type="datetime-local"
                    variant="standard"
                    fullWidth
                    className={classes.commonInputStyle}
                    InputProps={{
                      className: classes.input,
                    }}
                    value={selectedDate}
                    onChange={onDateChange}
                  />
                </GridItem>
              ) : null}
            </GridContainer>
            {/* <GridContainer spacing={2}>
              <GridItem
                xs={12}
                sm={12}
                md={6}
                lg={6}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  paddingTop: 30,
                }}
              >
                <ParcelsInput onParcelsChange={setParcels} />
                <ParcelSection onParcelsChange={setParcels} parcels={parcels} />
              </GridItem>
            </GridContainer> */}
            <GridContainer
              style={{ marginTop: 30, marginBottom: 20 }}
              spacing={2}
            >
              <GridItem
                xs={12}
                sm={12}
                md={bookingType === "Book Later" ? 6 : 6}
                lg={bookingType === "Book Later" ? 6 : 6}
              >
                <Button
                  color="#35B368"
                  size="lg"
                  rel="noopener noreferrer"
                  className={classes.items}
                  onClick={handleGetOptions}
                  style={{
                    height: bookingType === "Book Later" ? 52 : 52,
                    fontSize: 20,
                  }}
                >
                  {language.book}
                </Button>
              </GridItem>
            </GridContainer>
          </div>
        ) : null}
      </div>
      <Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        open={optionModalStatus}
        onClose={handleOptionModalClose}
        className={classes.modal}
        container={() => rootRef.current}
      >
        <Grid container spacing={2} className={classes.paper}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            {selectedCarDetails && selectedCarDetails.parcelTypes ? (
              <FormControl component="fieldset">
                <FormLabel component="legend">
                  {language.parcel_types}
                </FormLabel>
                <RadioGroup
                  name="parcelTypeIndex"
                  value={instructionData.parcelTypeIndex}
                  onChange={handleChange}
                >
                  {selectedCarDetails.parcelTypes.map((element, index) => (
                    <FormControlLabel
                      key={element.description}
                      value={index}
                      control={<Radio />}
                      label={
                        settings.symbol +
                        " " +
                        element.amount +
                        " - " +
                        element.description
                      }
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            ) : null}
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            {selectedCarDetails && selectedCarDetails.options ? (
              <FormControl component="fieldset">
                <FormLabel component="legend">{language.options}</FormLabel>
                <RadioGroup
                  name="optionIndex"
                  value={instructionData.optionIndex}
                  onChange={handleChange}
                >
                  {selectedCarDetails.options.map((element, index) => (
                    <FormControlLabel
                      key={element.description}
                      value={index}
                      control={<Radio />}
                      label={
                        settings.symbol +
                        " " +
                        element.amount +
                        " - " +
                        element.description
                      }
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            ) : null}
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Button
              onClick={handleGetEstimate}
              variant="contained"
              color="#35B368"
              style={{ marginLeft: 10 }}
            >
              {language.get_estimate}
            </Button>
            <Button
              simple
              onClick={handleOptionModalClose}
              variant="contained"
              color="warning"
            >
              {language.cancel}
            </Button>
          </Grid>
        </Grid>
      </Modal>
      <Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        open={estimateModalStatus}
        onClose={handleEstimateModalClose}
        className={classes.modal}
        container={() => rootRef.current}
      >
        <Grid container spacing={1} className={classes.paper}>
          <Typography
            component="h3"
            variant="h6"
            //color="primary"
            style={{ marginTop: 15 }}
            className={classes.title}
          >
            {language.delivery_information}
          </Typography>
          <Grid item xs={12}>
            <TextField
              color="success"
              variant="standard"
              fullWidth
              id="deliveryPerson"
              label={language.deliveryPerson}
              name="deliveryPerson"
              autoComplete="deliveryPerson"
              onChange={handleChange}
              value={instructionData.deliveryPerson}
              autoFocus
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              color="success"
              variant="standard"
              fullWidth
              id="deliveryPersonPhone"
              label={language.deliveryPersonPhone}
              name="deliveryPersonPhone"
              autoComplete="deliveryPersonPhone"
              onChange={handleChange}
              value={instructionData.deliveryPersonPhone}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              color="success"
              variant="outlined"
              multiline
              rows={3}
              fullWidth
              id="pickUpInstructions"
              label={language.pickUpInstructions}
              name="pickUpInstructions"
              autoComplete="pickUpInstructions"
              onChange={handleChange}
              value={instructionData.pickUpInstructions}
            />
          </Grid>
          {/* //To add if its type of contract to show, otherwise NOT */}
          <Grid item xs={12}>
              <TextField
                color="success"
                variant="outlined"
                //helperText="If you have contract with us please complete the special code, otherwise leave it blank"
                fullWidth
                id="deliveryInstructions"
                label={language.deliveryInstructions}
                name="deliveryInstructions"
                autoComplete="deliveryInstructions"
                onChange={handleChange}
                value={instructionData.deliveryInstructions}
              />
            </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography color={"warning"} style={{ fontSize: 24 }}>
              {language.total} - {settings ? settings.symbol : null}{" "}
              {estimatedata.estimate
                ? estimatedata.estimate.estimateFare
                : null}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Button
              onClick={confirmBooking}
              variant="contained"
              color="#35B368"
              style={{ marginLeft: 10 }}
            >
              {language.book_now}
            </Button>
            <Button onClick={handleEstimateModalClose} simple color="warning">
              {language.cancel}
            </Button>
          </Grid>
        </Grid>
      </Modal>
      <AlertDialog
        open={bookingdata.booking ? true : false}
        onClose={handleBookingAlertClose}
      >
        {bookingdata.booking
          ? language.booking_success + bookingdata.booking.booking_id
          : null}
      </AlertDialog>
      <AlertDialog
        open={bookingdata.error.flag}
        onClose={handleBookingErrorClose}
      >
        {bookingdata.error.msg}
      </AlertDialog>
      <AlertDialog
        open={estimatedata.error.flag}
        onClose={handleEstimateErrorClose}
      >
        {estimatedata.error.msg}
      </AlertDialog>
      <AlertDialog open={commonAlert.open} onClose={handleCommonAlertClose}>
        {commonAlert.msg}
      </AlertDialog>
    </div>
  );
}
