import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import List from "@material-ui/core/List";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import { language } from "config";
import moment from "moment";

function ProductsModal(props) {
  const { visible, onClose, booking } = props;
  const _onClose = () => {
    onClose();
  };

  const _onFinish = () => {
    _onClose();
  };

  return (
    <>
      <Dialog fullWidth maxWidth="sm" open={visible} onClose={_onClose}>
        <DialogTitle>
          {language.product_order} #{booking?.number}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {language.total_of} {booking?.total}
            {booking?.currency_symbol || ""}
          </DialogContentText>
          <List>
            {(Array.isArray(booking?.line_items)
              ? booking?.line_items
              : []
            ).map((item, index) => {
              let secondary = "N° " + item.number;
              if (item?.scanned) {
                secondary =
                  secondary +
                  " - " +
                  language.scanned_at +
                  " " +
                  moment(item.scannedAt).format("DD MMM YYYY hh:mm A");
              }
              return (
                <ListItem key={item.number}>
                  <ListItemText
                    style={{ color: "black" }}
                    primary={item?.name}
                    secondary={`${item?.quantity}x${item?.price}${
                      booking?.currency_symbol || ""
                    }`}
                  />
                </ListItem>
              );
            })}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={_onFinish}>{language.close}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ProductsModal;
