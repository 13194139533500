import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import HeaderLinks from "components/Header/HeaderLinks.js";
import image from "assets/img/background.jpg";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { useSelector } from "react-redux";
import {language} from 'config';


const dashboardRoutes = [];

const useStyles = makeStyles(theme => ({
  ...styles,
 

}));

export default function LandingPage(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const settings = useSelector(state => state.settingsdata.settings);

  return (
  <div>
          <Header
            color="transparent"
            absolute
            routes={dashboardRoutes}
            rightLinks={<HeaderLinks />}
            {...rest}
          />
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center"
        }}
      >
      <div>
          {/* <GridContainer className={classes.textCenter} justify="center"> */}
          {/* <GridItem xs={12} sm={12} md={8} style={{ paddingLeft:300, paddingTop:180, paddingBottom:30}}> */}
          <Container maxWidth="sm">
            <Grid  container direction="column" justifyContent="center" alignItems="center" style={{ paddingTop:180, paddingBottom:30}} >
            <h2 className={classes.title}>{language.mobile_apps_on_store}</h2>
          </Grid>
          </Container>
          <Container maxWidth="sm">
              {/* <GridItem xs={12} sm={8} md={6} style={{ paddingLeft:200, paddingBottom:200}}> */}
              <Grid container direction="row" justifyContent="center" alignItems="center" style={{ paddingBottom:280}}>
                {settings && settings.TwitterHandle?
                <a href={settings.TwitterHandle}><img src={"https://tennisnovels.devpap.co.uk/wp-content/uploads/2023/02/appstore.png"} alt="Apple Store Link"/></a>
                :null}
                <span style={{marginRight: '5px'}}></span>
                {settings && settings.FacebookHandle?
                <a href={settings.FacebookHandle}><img src={"https://tennisnovels.devpap.co.uk/wp-content/uploads/2023/02/playstore.png"} alt="Playstore Link"/></a>
                :null}
              </Grid>
              </Container>
            
      </div>
      <Footer />
    </div>
  </div>
  );
}
